const checkList = '/task-activity-monitoring/check-list/'
export const checkListList = checkList + 'list'
export const coordinatorTask = checkList + 'coordinator-task'
export const checkListStore = checkList + 'store'
export const checkListUpdate = checkList + 'update'
export const checkListTogle = checkList + 'toggle-status'
export const checkListShow = checkList + 'show'
export const checkListForward = checkList + 'forward'
export const checkListForwardedList = checkList + 'forwarded-list'
export const checkListApprove = checkList + 'approve'
export const checkListReject = checkList + 'reject'
export const checkListReview = checkList + 'review'
export const checkListApprovedList = checkList + 'approved-list'

const assignTaskCoordinator = '/research-plan-preparation/assign-task-coordinator/'
export const assignTaskCoordinatorList = assignTaskCoordinator + 'list'
export const assignTaskCoordinatorToggle = assignTaskCoordinator + 'toggle-status'
export const userListByDesignWise = '/user-management/user/list-by-designation'

const task = '/task-activity-monitoring/task/'
export const taskList = task + 'list'
export const taskUpdate = task + 'update'
export const taskHistory = task + 'task-history'
export const taskToggle = task + 'toggle-status'
export const taskReview = task + 'task-review'
export const taskShow = task + 'show'

const assign = '/task-activity-monitoring/assign-consultant/'
export const assignConsultantList = assign + 'list'
export const assignConsultantUpdate = assign + 'update'
export const assignConsultantTogle = assign + 'toggle-status'
export const assignConsultantShow = assign + 'show'

const bftiExternal = '/bfti-panel/research-and-other-request/'
export const bftiResearchRequestAdminList = bftiExternal + 'admin-list'

const bfti = '/research-and-other/approved-research-and-other-request/'
export const bftiResearchRequestApprovedList = bfti + 'list'

export const taskActivityReport = '/task-activity-monitoring/task-activity-report'
